import { GAME_PAGE_URL } from '~/constants/lobby'
import {
  IMenuHeader,
  IMenuUserDesktop,
  IMenuWithDraw,
  ISubMenuHeader,
  IMenuDepositBank,
  IMenuDepositDesktop,
  IMenuDeposit,
  IMenuMobile,
  IMenuBottom
} from '~/types/menu.type'
import { ACCOUNT_URLS, LOBBY_GAME_URLS, PAGE_URLS, PROMOTION_URLS } from '~/config/page-url'
import { LABEL } from '~/constants/account/history/transactions'
import { PATH_BRAND } from '~/constants/path'

export const PATH_ICON = '/assets/images/components/desktop/menu/'

export const PATH_ICON_ACCOUNT = '/assets/images/components/common/'

export const LOGO_SITE_IMAGE = '/assets/brand/logo.webp'

export const IMAGE_PATH_ACCOUNT = '/assets/images/components/desktop/pages/account/layout/'
export const IMAGE_PATH_DEPOSIT = '/assets/images/components/desktop/pages/account/deposit/'
export const TRANSACTION_P2P_TYPE = {
  BUY: 'buy',
  SELL: 'sell'
}

export const PROMOTION_SUBMENU: ISubMenuHeader[] = [
  {
    id: 1,
    name: 'promotion',
    display_name: 'Thể Thao Thưởng 1.58%',
    url: PROMOTION_URLS.olympic_2024,
    activeUrl: [PROMOTION_URLS.olympic_2024],
    alias: PROMOTION_URLS.olympic_2024,
    icon: {
      inactive: PATH_ICON + 'promotion/0.5.svg',
      active: PATH_ICON + 'promotion/0.5.svg'
    },
    type: 'olympic_2024',
    disable: false
  },
  {
    id: 2,
    name: 'promotion',
    display_name: 'Thể Thao Thưởng 1.58%',
    url: PROMOTION_URLS.promotion_05,
    activeUrl: [PROMOTION_URLS.promotion_05],
    alias: PROMOTION_URLS.olympic_2024,
    icon: {
      inactive: PATH_ICON + 'promotion/0.5.svg',
      active: PATH_ICON + 'promotion/0.5.svg'
    },
    type: 'promotion_05',
    disable: false,
    isNotShowLine: true
  },
  {
    id: 3,
    name: 'promotion',
    display_name: 'Game Bài Thưởng 0.5%',
    url: PROMOTION_URLS.promotion_158,
    activeUrl: [PROMOTION_URLS.promotion_158],
    alias: PROMOTION_URLS.olympic_2024,
    icon: {
      inactive: PATH_ICON + 'promotion/1.58.svg',
      active: PATH_ICON + 'promotion/1.58.svg'
    },
    type: 'promotion_158',
    disable: false,
    isNotShowLine: true
  },
  {
    id: 4,
    name: 'promotion',
    display_name: 'Game Bài Thưởng 0.5%',
    url: PROMOTION_URLS.promotion_158,
    activeUrl: [PROMOTION_URLS.promotion_158],
    alias: PROMOTION_URLS.olympic_2024,
    icon: {
      inactive: PATH_ICON + 'promotion/1.58.svg',
      active: PATH_ICON + 'promotion/1.58.svg'
    },
    type: 'promotion_158',
    disable: false,
    isNotShowLine: true
  },
  {
    id: 5,
    name: 'promotion',
    display_name: 'EURO 2024',
    url: PROMOTION_URLS.funding,
    activeUrl: [PROMOTION_URLS.funding],
    alias: PROMOTION_URLS.olympic_2024,
    icon: {
      inactive: PATH_ICON + 'promotion/euro2024.svg',
      active: PATH_ICON + 'promotion/euro2024.svg'
    },
    type: 'funding',
    disable: false
  }
]

export const MENUS: IMenuHeader[] = [
  {
    type: 'sports',
    name: 'the-thao',
    tab: 'tab-sport',
    title: 'Thể thao',
    icon: '/assets/images/components/desktop/header/icon-sport.svg',
    iconActive: '/assets/images/components/desktop/header/icon-sport-active.svg',
    activeUrl: PAGE_URLS.SPORT
  },
  {
    type: 'livecasino',
    name: 'livecasino',
    title: 'Live casino',
    icon: '/assets/images/components/desktop/header/icon-casino.svg',
    iconActive: '/assets/images/components/desktop/header/icon-casino-active.svg',
    tab: 'tab-casino',
    activeUrl: GAME_PAGE_URL.all,
    subMenu: []
  },
  {
    type: 'games',
    name: 'game-type',
    title: 'Cổng game',
    tab: 'tab-games',
    icon: '/assets/images/components/desktop/header/icon-games.svg',
    iconActive: '/assets/images/components/desktop/header/icon-games-active.svg',
    activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
    subMenu: []
  }
]

export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-icon-profile',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-profile'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: 'icon-deposit2',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit2'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-withdraw2',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw2'
  },
  {
    id: 4,
    name: 'Mua bán P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-icon-p2p',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-p2p'
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-account-history'
  },
  {
    id: 6,
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.BONUS,
    icon: 'icon-icon-gift',
    activeUrl: ACCOUNT_URLS.BONUS,
    activeUrlRelative: ACCOUNT_URLS.BONUS,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-gift'
  },
  {
    id: 7,
    name: 'Ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-icon-bank',
    activeUrl: ACCOUNT_URLS.BANK,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-bank'
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: '',
    icon: 'icon-icon-logout',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const ACCOUNT_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-icon-profile',
    img: PATH_ICON_ACCOUNT + 'help-register.svg',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: 'icon-deposit2',
    img: PATH_ICON_ACCOUNT + 'icon-deposit.svg',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-withdraw2',
    img: PATH_ICON_ACCOUNT + 'icon-withdraw.svg',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 4,
    name: 'Mua bán P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-icon-p2p',
    img: PATH_ICON_ACCOUNT + 'icon-p2pc.svg',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history',
    img: PATH_ICON_ACCOUNT + 'icon-history.svg',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 6,
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.PROFIT_TODAY,
    icon: 'icon-icon-gift',
    img: PATH_ICON_ACCOUNT + 'icon-bonus.svg',
    activeUrl: ACCOUNT_URLS.PROFIT_TODAY,
    activeUrlRelative: ACCOUNT_URLS.PROFIT_TODAY,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 7,
    name: 'Ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-icon-bank',
    img: PATH_ICON_ACCOUNT + 'icon-bank.svg',
    activeUrl: ACCOUNT_URLS.BANK,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: '',
    icon: 'icon-logout',
    img: PATH_ICON_ACCOUNT + 'icon-logout.svg',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank-bold',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Mua bán P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'sell',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`,
    icon: 'icon-p2pc',
    iconMB: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_WITHDRAW_DESKTOP: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    nameMB: 'Codepay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    nameMB: 'Codepay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Nạp P2P',
    nameMB: 'Mua bán P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'buy',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    key: 'buy',
    icon: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    nameMB: 'Tiền ảo',
    desc: 'Nạp USDT tức thì',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    icon: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    nameMB: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: ACCOUNT_URLS.DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    nameMB: 'Thẻ cào',
    desc: 'Nhiều nhà mạng hỗ trợ',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân Hàng',
    queryValue: 'codepay',
    fullPath: [ACCOUNT_URLS.DEPOSIT_BANK, ACCOUNT_URLS.DEPOSIT_DA, ACCOUNT_URLS.DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền Ảo',
    desc: 'Nạp USDT tức thì',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví Điện Tử',
    queryValue: 'e-wallet',
    fullPath: [ACCOUNT_URLS.DEPOSIT_MOMO, ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY, ACCOUNT_URLS.DEPOSIT_ZALO_PAY],
    key: 'e-wallet',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ Cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]
export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    pathActive: ACCOUNT_URLS.DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: 'icon-codepay',
    isHot: true,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: 'icon-codepay2',
    isHot: false,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(
      () => import('~/components/mobile/pages/account/history/transactions/index.vue')
    )
  },
  {
    name: LABEL.HISTORY_BETS,
    link: ACCOUNT_URLS.BET_HISTORY,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const ACCOUNT_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-icon-profile',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-icon-bank',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Mua bán P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-icon-p2p',
    activeUrl: [ACCOUNT_URLS.P2P],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${ACCOUNT_URLS.TRANSACTION_HISTORY}`,
    icon: 'icon-icon-history-transaction',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${ACCOUNT_URLS.BET_HISTORY}`,
    icon: 'icon-icon-history-bets',
    activeUrl: [ACCOUNT_URLS.BET_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.PROFIT_TODAY,
    icon: 'icon-icon-gift',
    img: PATH_ICON_ACCOUNT + 'icon-bonus.svg',
    activeUrl: [''],
    isLiveChat: false
  },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]

export const BOTTOM_MENU: IMenuBottom[] = [
  {
    name: 'Khuyến mãi',
    url: PAGE_URLS.PROMOTION,
    activeUrl: PAGE_URLS.PROMOTION,
    icon: PATH_ICON + 'icon-gift.svg'
  },
  {
    name: 'Trang chủ',
    url: PAGE_URLS.HOME,
    activeUrl: PAGE_URLS.HOME,
    icon: PATH_ICON + 'icon-home.svg'
  },
  {
    name: 'Thể thao',
    url: PAGE_URLS.SPORT,
    activeUrl: PAGE_URLS.SPORT,
    icon: PATH_ICON + 'icon-sport.svg'
  },
  {
    name: 'Cổng game',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: PAGE_URLS.GAME,
    icon: PATH_BRAND + 'logo.webp'
  },
  {
    name: 'Quay số',
    url: LOBBY_GAME_URLS.QUAY_SO,
    activeUrl: LOBBY_GAME_URLS.QUAY_SO,
    icon: PATH_ICON + 'icon-lottery.svg'
  },
  {
    name: 'Live casino',
    url: GAME_PAGE_URL.all,
    activeUrl: GAME_PAGE_URL.all,
    icon: PATH_ICON + 'icon-livecasino.svg'
  }
]
